import s from './StatCards.module.scss'

import SingleCard from "../../../Dashboard/StatCards/SingleCard/SingleCard";

import icon1 from './icon1.png'
import icon2 from './icon2.png'
import icon3 from './icon3.png'

import {getTicketsCount} from "../../../../../services/StatisticService";


const periods = [{text:'Сегодня',code:'DAY'}]

const formatTicketCount  = async (period, type) => {
    try {
        const counter = await getTicketsCount(period, type)
        return typeof counter === 'number' ? counter : counter.reduce((sum, curr) => sum + curr[1], 0)
    } catch (e) {
        throw e
    }
}

const StatCards = () => {


    return <div className={s.wrap}>
        <SingleCard periods={[{text:'Сейчас',code:'DAY'}]}
                    title={"Обращений в очереди"}
                    theme={'blue'}
                    icon={<img src={icon1} alt={''}/>}
                    getDataApi={(period) => formatTicketCount(period, "IN_QUEUE")}
                    style={{padding:32}}
        />
        <SingleCard periods={[{text:'Сейчас',code:'DAY'}]}
                    title={"Обращений в работе"}
                    icon={<img src={icon2} alt={''}/>}
                    getDataApi={(period) => formatTicketCount(period, "IN_WORK")}
                    style={{padding:32}}
        />
        <SingleCard periods={periods}
                    title={"Обращений закрыто"}
                    icon={<img src={icon3} alt={''}/>}
                    getDataApi={(period) => formatTicketCount(period, "CLOSE")}
                    style={{padding:32}}
        />
    </div>
}

export default StatCards