import s from './OrderDetails.module.scss'
import f from '../../../../../static/fonts.module.scss'
import style from "../../../OrderList/TableRow/TableRow.module.scss";

import {timestampToDate} from "../../../../../static/utilities";
import {useState} from "react";

import {ReactComponent as File} from "./File.svg";
import {ReactComponent as Edit} from "./Edit.svg";

import {categoryTag, statusTag} from "../../../OrderList/TableRow/TableRow";
import SpecList from "../../../../_BASE/Layout/SpecList/SpecList";
import Tabs from "../../../../_BASE/Layout/TabsTable/Tabs";
import Fancybox from "../../../../_BASE/UI/Fancybox/Fancybox";
import Reviews from "./Reviews/Reviews";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Select from "../../../../_BASE/UI/Select/Select";
import {notifications} from "../../../../../index";
import {updateOrderStatus} from "../../../../../services/OrdersService";

const OrderDetails = ({data, orderId, editMode = true, getData}) => {
    const [activeTaskTab, setActiveTaskTab] = useState('Описание задания');
    const [orderStatusEditModal, setOrderStatusEditModal] = useState(false);

    const params = [
        {
            label: 'Статус заказа:',
            value: <div className={s.actionWrap}>
                {statusTag(data.orderStatusTitle)}
                {
                    editMode && <button
                        onClick={() => setOrderStatusEditModal(true)}
                        title={'Изменить статус заказа'}
                        className={s.editButton}><Edit/></button>
                }
            </div>,
            complexValue: true
        },
        {
            label: 'Категории заказа:',
            value: <div className={style.tagContainer}>
                {categoryTag(data.competency?.subjectTitle, 'big')}
                {categoryTag(data.competency?.subjectOption, 'big')}
            </div>,
            complexValue: true
        },
        {
            label: 'Индентификатор заказа:',
            value: orderId,
        },
        {
            label: 'Стоимость заказа:',
            value: data.price + " ₽",
        },
        {
            label: 'Дата создания заказа:',
            value: data.createDate && timestampToDate(data.createDate),
        },
        {
            label: 'Дата закрытия заказа:',
            value: data.statusChangeDate && timestampToDate(data.statusChangeDate),
        },
        {
            label: 'Срок сдачи от заказчика:',
            value: data.deadline && timestampToDate(data.deadline),
        },
        {
            label: 'Дата истечения гарантии:',
            value: data.warrantyEndDate && timestampToDate(data.warrantyEndDate),
        },
        {
            label: 'Оригинальность работы:',
            value: data.originality,
        },
        {
            label: 'Количество задач:',
            value: data.taskNumber,
        },
        {
            label: 'Заголовок задания:',
            value: data.orderTitle,
            type: 'vertical',
        },
    ]

    const taskTabs = [
        {
            label: 'Описание задания',
            content: <p className={f.body1}>{data.orderComment}</p>
        },
        {
            label: 'Файлы задания',
            content: <Fancybox>
                <div className={s.attFiles}>
                    {
                        data?.imageLinkArray.map((url, i) => <SingleAttachment url={url}
                                                                               key={url + i}/>)
                    }
                </div>
            </Fancybox>
        }
    ]


    return <div className={s.infoWrap}>
        <SpecList params={params}/>
        <Tabs tabs={taskTabs} activeTab={activeTaskTab} setActiveTab={setActiveTaskTab}/>
        <Reviews orderId={orderId}/>
        {
            orderStatusEditModal &&
            <OrderStatusEditModal
                orderId={orderId}
                closeFunc={setOrderStatusEditModal}
                updateData={getData}
                data={data}
            />
        }
    </div>
}

const OrderStatusEditModal = ({orderId, closeFunc, data, updateData}) => {
    const [selectedStatus, setSelectedStatus] = useState();
    const [isUpdating, setIsUpdating] = useState(false);

    const options = ["ORDER_CANCELLED", "ORDER_PAID", "ORDER_IN_PROGRESS", "ORDER_CLOSE"]

    const updateOrder = async () => {
        try {
            setIsUpdating(true)
            const req = await updateOrderStatus({
                code: selectedStatus,
                id: orderId
            })
            notifications.addNotification(req.message, "OK")
            closeFunc(false)
            await updateData()
        } catch (e) {
            notifications.addNotification(e.response.data.message)
            console.log(e)
        } finally {
            setIsUpdating(false)
        }
    }

    return <ModalWindow closeFunc={closeFunc}
                        type={'small'}
                        title={'Изменение статуса заказа'}
                        primaryAction={updateOrder}
                        primaryButtonIsDisabled={isUpdating}
    >
        <div className={s.modalWrap}>
            <p className={f.subtitle1Bold}>Текущий статус заказа</p>
            {statusTag(data.orderStatusTitle)}
            <Select title={'Выберите новый статус заказа'}
                    options={options}
                    selectedOption={selectedStatus}
                    setSelectedOption={setSelectedStatus}
            />
        </div>
    </ModalWindow>
}

const SingleAttachment = ({url}) => {
    const fileName = url.split('/').slice(-1)[0]
    const type = getFileType(fileName)

    const file = <div
        title={fileName}
        className={s.fileIcon}>
        <File/>
    </div>


    return <>{
        type !== "doc_without_preview"
            ? <div key={url}
                   data-fancybox="gallery"
                   data-src={url}
            >
                {
                    type === 'image' &&
                    <img className={s.modalImgPrev} src={url} alt=""/>
                }
                {type === 'doc_with_preview' &&
                    file
                }
            </div>
            : <a href={url} target={'_blank'} rel="noreferrer">
                {file}
            </a>
    }
    </>
}

const getFileType = (fileName) => {
    const fileType = fileName.split('.').slice(-1)[0]

    switch (fileType) {
        case "jpg":
        case "jpeg":
        case "png":
            return "image"

        case "pdf":
            return "doc_with_preview"

        default :
            return "doc_without_preview"
    }
}

export default OrderDetails