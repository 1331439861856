import s from './CreateModal.module.scss'
import ModalWindow from "../../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useEffect, useState} from "react";
import Input from "../../../../../_BASE/UI/Input/Input";
import FileInput from "../../../../../_BASE/UI/Input/FileInput/FileInput";
import Select from "../../../../../_BASE/UI/Select/Select";
import {uploadImage} from "../../../../../../services/FileService";
import {createTicketSection, editTicketSection} from "../../../../../../services/SupportService";

const CreateModal = ({data, closeFunc, updateData}) => {
    const [name, setName] = useState('');
    const [type, setType] = useState();

    const [iconURL, setIconURL] = useState();
    const [iconObject, setIconObject] = useState();

    const [isCreating, setIsCreating] = useState(false);

    const getCode = (text) => {
        switch (text) {
            case "Для всех":
                return "ALL"
            case "Для учителей":
                return "TEACHER"
            case "Для учеников":
                return "STUDENT"
            default:
                return
        }
    }

    const createSection = async () => {
        try {
            setIsCreating(true)
            const image = await uploadImage(iconObject)
            await createTicketSection({
                iconLink: image.fileLink,
                title: name.trim(),
                type: getCode(type)
            })
            await updateData()
            closeFunc(false)
            setIsCreating(false)
        } catch (e) {
            console.log(e)
        }
    }

    const editSection = async () => {
        try {
            setIsCreating(true)
            let iconUrl = data.imageLink
            if (iconObject) {
                const image = await uploadImage(iconObject)
                iconUrl = image.fileLink
            }

            await editTicketSection(data.id, {
                iconLink: iconUrl,
                title: name.trim(),
                code: getCode(type)
            })
            await updateData()
            closeFunc(false)
            setIsCreating(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (data) {
            setName(data.title)
            setType(data.typeTitle)
            setIconURL(data.imageLink)
        }
    }, [data])


    return <ModalWindow closeFunc={closeFunc}
                        type={'small'}
                        primaryButtonIsDisabled={
                            !(type && name.trim().length > 0 && iconURL && !isCreating)
                        }
                        primaryButtonTitle={data ? "Изменить" : 'Создать'}
                        primaryAction={data ? editSection : createSection}
                        title={data
                            ? "Изменение секции"
                            : `Создание секции обращений`}
    >
        <div className={s.wrap}>
            <Input value={name} setValue={setName} title={'Укажите название'}
                   styles={{maxWidth: "100%"}}
            />
            <FileInput imageObject={iconObject} setImageObject={setIconObject}
                       imageUrl={iconURL} setImageUrl={setIconURL}/>
            <Select
                options={["Для всех", "Для учителей", "Для учеников"]}
                selectedOption={type} setSelectedOption={setType}
                title={'Выберите категорию'}
            />
        </div>
    </ModalWindow>
}

export default CreateModal